import Dust from "../../imgs/white-dust.svg";
import { FurnitureCardSummaryRow } from "./FurnitureCardSummaryRow";
import { PricedCardSummaryRow } from "./PricedCardSummaryRow";
import { NotPricedCardSummaryRow } from "./NotPricedCardSummaryRow";
import { useSbsContext } from "../../context/sbscontext";
import { furnitureOptions, serviceOptions } from "../organisms/FormSection";
import { useState, useEffect } from "react";


// CONFIGURACION DE PRECIOS
export const minimumLaborPrice = 139
export const commercialCarpetCleaningPrice = 0.35
export const areaRugCleaningPrice = 0.75

function getRoomsPrice(amount){
  switch (amount) {
    case "0":
      return 0;
    case "1":
      return 55;
    case "2":
      return 2 * 45;
    case "3":
      return 3 * 40;
    default:
      return amount * 36;
  }
}

function getEntryOrHallPrice(amount){
  switch (amount) {
    case "0":
      return 0;
    case "1":
      return 48;
    case "2":
      return 2 * 35;
    default:
      return amount * 30;
  }
}

function getStaircasePrice(amount){
  switch (amount) {
    case "0":
      return 0;
    case "1":
      return 52;
    default:
      return amount * 45;
  }
}

function getCommercialCarpetCleaningTotalPrice(area){
  return (area * commercialCarpetCleaningPrice.toFixed(2))
}

function getAreaRugTotalPrice(length, width){
  return (length * width * areaRugCleaningPrice.toFixed(2))
}

function getChairsPrice(amount){
  if(!amount) return 0

  return amount <= 5
      ? amount * 38
      : amount * 30
}

function getKitchenChairsPrice(amount){
  if(!amount) return 0

  return amount <= 5
      ? amount * 22
      : amount * 18
}

function getLoveseatsPrice(amount){
  if(!amount) return 0
  else if(amount === 1) return 139
  else if(amount === 2) return amount * 100
  else return amount * 96
}

function getSectionalsPrice(amount){
  if(!amount) return 0
  else if(amount === 1) return 199
  else if(amount === 2) return amount * 159
  else return amount * 129
}

function getSofasPrice(amount){
  if(!amount) return 0
  else if(amount === 1) return 139
  else return amount * 119
}

function getFurnitureCleaningTotalPrice(chairsAmount, loveseatsAmount, kitchenChairsAmount, sofasAmount, sectionalsAmount){
  return (getChairsPrice(chairsAmount) +
  getLoveseatsPrice(loveseatsAmount) +
  getKitchenChairsPrice(kitchenChairsAmount) +
  getSofasPrice(sofasAmount) +
  getSectionalsPrice(sectionalsAmount))
}

function getCarpetCleaningTotalPrice(carpetCleaningRoomsAmount, carpetCleaningEntryOrHallAmount,carpetCleaningStaircaseAmount){
  return (getRoomsPrice(carpetCleaningRoomsAmount) +
  getEntryOrHallPrice(carpetCleaningEntryOrHallAmount) +
  getStaircasePrice(carpetCleaningStaircaseAmount))
}

function showGlobalTotal(globalTotal){
  return globalTotal <= minimumLaborPrice ? minimumLaborPrice : globalTotal
}

export const SummaryCard = () => {

  const {
    selectedService,
    carpetCleaningRoomsAmount,
    carpetCleaningEntryOrHallAmount,
    carpetCleaningStaircaseAmount,
    commercialCarpetCleaningArea,
    chairsAmount,
    loveseatsAmount,
    kitchenChairsAmount,
    sofasAmount,
    sectionalsAmount,
    setIsModalOpen,
    areaRugLength,
    areaRugWidth,
    carpetCleaningTotal,
    setCarpetCleaningTotal,
    commercialCarpetCleaningTotal,
    setCommercialCarpetCleaningTotal,
    furnitureCleaningTotal,
    setFurnitureCleaningTotal,
    areaRugCleaningTotal,
    setAreaRugCleaningTotal,
    globalTotal,
    setGlobalTotal
  } = useSbsContext()

  const [roomsPrice, setRoomsPrice] = useState(0);
  const [entryOrHallPrice, setEntryOrHallPrice] = useState(0);
  const [staircasePrice, setStaircasePrice] = useState(0);

  useEffect(() => {
    const updatedPrice = getRoomsPrice(carpetCleaningRoomsAmount);
    setRoomsPrice(updatedPrice);
  }, [carpetCleaningRoomsAmount]);

  useEffect(() => {
    const updatedPrice = getEntryOrHallPrice(carpetCleaningEntryOrHallAmount);
    setEntryOrHallPrice(updatedPrice);
  }, [carpetCleaningEntryOrHallAmount]);

  useEffect(() => {
    const updatedPrice = getStaircasePrice(carpetCleaningStaircaseAmount);
    setStaircasePrice(updatedPrice);
  }, [carpetCleaningStaircaseAmount]);

  useEffect(() => {
    const totalPrice = getAreaRugTotalPrice(areaRugLength, areaRugWidth);
    setAreaRugCleaningTotal(totalPrice);
  }, [areaRugLength,areaRugWidth,setAreaRugCleaningTotal]);

  useEffect(() => {
    const totalPrice = getCommercialCarpetCleaningTotalPrice(commercialCarpetCleaningArea);
    setCommercialCarpetCleaningTotal(totalPrice);
  }, [commercialCarpetCleaningArea,setCommercialCarpetCleaningTotal]);

  useEffect(() => {
    const totalPrice = getFurnitureCleaningTotalPrice(chairsAmount, loveseatsAmount, kitchenChairsAmount, sofasAmount, sectionalsAmount);
    setFurnitureCleaningTotal(totalPrice);
  }, [chairsAmount, loveseatsAmount, kitchenChairsAmount, sofasAmount, sectionalsAmount,setFurnitureCleaningTotal]);

  useEffect(() => {
    const totalPrice = getCarpetCleaningTotalPrice(carpetCleaningRoomsAmount, carpetCleaningEntryOrHallAmount,carpetCleaningStaircaseAmount);
    setCarpetCleaningTotal(totalPrice);
  }, [carpetCleaningRoomsAmount, carpetCleaningEntryOrHallAmount,carpetCleaningStaircaseAmount,setCarpetCleaningTotal]);

  useEffect(() => {
    const totalPrice = carpetCleaningTotal +
    commercialCarpetCleaningTotal +
    furnitureCleaningTotal +
    areaRugCleaningTotal
    setGlobalTotal(totalPrice);
  }, [carpetCleaningTotal,commercialCarpetCleaningTotal,furnitureCleaningTotal,areaRugCleaningTotal, setGlobalTotal]);


  return (
    <div className="bg-white h-fit w-full md:w-[420px] rounded-md text-slate-800 shadow-sm">
      <div className="p-8 mb-4 bg-indigo-500 rounded-tl-md rounded-tr-md rounded-bl-none rounded-br-none text-white flex items-center justify-between">
        <p className="font-bold">Summary</p>
        <img src={Dust} alt="" />
      </div>

      {/* Carpet Cleaning Pricing */}
      {
        selectedService === serviceOptions.carpetCleaning && <div>
          <div className="grid grid-rows-4">
            <PricedCardSummaryRow
              label="Rooms"
              value={`${carpetCleaningRoomsAmount}`}
              m2="Steam Clean 1m2"
              coin="USD"
              price={roomsPrice}
            />
            <PricedCardSummaryRow
              label="Entries or Halls"
              value={`${carpetCleaningEntryOrHallAmount}`}
              m2="Steam Clean 1m2"
              coin="USD"
              price={entryOrHallPrice}
            />
            <PricedCardSummaryRow
              label="Staircases"
              value={`${carpetCleaningStaircaseAmount}`}
              m2="Steam Clean 1m2"
              coin="USD"
              price={staircasePrice}
            />
          </div>

          <div className="p-8 border-t-2 w-full	border-slate-50	border-solid">
            <div className="grid grid-cols-2 w-full">
              <div className="h-16">
                <p className="text-base font-bold">Total</p>
                <p className="text-slate-400 text-sm">
                  <span className="text-rose-500">*</span> Minimum labor price
                  {minimumLaborPrice.toString()}USD
                </p>
              </div>
              <div className="flex justify-end h-16">
                <p className="text-xl text-indigo-500 font-bold">
                  {""}
                  <br className="service-total"/> {showGlobalTotal(globalTotal)} <span className="text-sm text-slate-400">USD</span>
                </p>
              </div>
            </div>
            <div className="flex mt-2 py-4 w-full">
              <button
                className="hover:bg-indigo-400 transition delay-300 duration-300 bg-indigo-500 italic uppercase py-4 px-6 rounded text-white text-sm font-bold w-full text-center"
                onClick={() => setIsModalOpen(true)}
              >
                Book Now!
              </button>
            </div>
          </div>
        </div>
      }


      {/* Commercial Carpet Cleaning Pricing */}
      {
        selectedService === serviceOptions.commercialCarpetCleaning && <div>
          <div className="grid grid-rows-4">
            <NotPricedCardSummaryRow
              label="Carpet area"
              value=""
              m2="Steam Clean Sq.Ft"
            />
            <NotPricedCardSummaryRow
              label="Area"
              value={`${commercialCarpetCleaningArea}`}
              m2=""
            />
          </div>

          <div className="p-8 border-t-2 w-full	border-slate-50	border-solid">
            <div className="grid grid-cols-2 w-full">
              <div className="h-16">
                <p className="text-base font-bold">Total</p>
                <p className="text-slate-400 text-sm">
                  <span className="text-rose-500">*</span> Minimum labor price
                  {minimumLaborPrice.toString()}USD
                </p>
              </div>
              <div className="flex justify-end h-16">
                <p className="text-xl text-indigo-500 font-bold">
                  {""}
                  <br className="service-total" /> {showGlobalTotal(globalTotal)} <span className="text-sm text-slate-400">USD</span>
                </p>
              </div>
            </div>
            <div className="flex mt-2 py-4 w-full">
              <button
                className="hover:bg-indigo-400 transition delay-300 duration-300 bg-indigo-500 italic uppercase py-4 px-6 rounded text-white text-sm font-bold w-full text-center"
                onClick={() => setIsModalOpen(true)}
              >
                Book Now!
              </button>
            </div>
          </div>
        </div>
      }

      {/* Furniture Cleaning Pricing */}
      {
        selectedService === serviceOptions.furnitureCleaning && <div>
          {
            chairsAmount <= 0 && loveseatsAmount <= 0 && kitchenChairsAmount <= 0 && sofasAmount <= 0 && sectionalsAmount <= 0 && <h2 className="ml-5 pl-3 font-bold">Add your items</h2>
          }
          <div className="grid grid-rows-4">
            {
              chairsAmount > 0 && <FurnitureCardSummaryRow
                  label={furnitureOptions.chair.label}
                  value={`${chairsAmount}`}
                  coin="USD"
                  price={getChairsPrice(chairsAmount)}
                  amount={chairsAmount}
              />
            }

            {
              loveseatsAmount > 0 && <FurnitureCardSummaryRow
                label={furnitureOptions.loveseat.label}
                value={`${loveseatsAmount}`}
                m2="Steam Clean 1m2"
                coin="USD"
                price={getLoveseatsPrice(loveseatsAmount)}
                amount={loveseatsAmount}
              />
            }

            {
              kitchenChairsAmount > 0 && <FurnitureCardSummaryRow
                  label={furnitureOptions.kitchenchair.label}
                  value={`${kitchenChairsAmount}`}
                  m2="Steam Clean 1m2"
                  coin="USD"
                  price={getKitchenChairsPrice(kitchenChairsAmount)}
                  amount={kitchenChairsAmount}
              />
            }

            {
              sofasAmount > 0 && <FurnitureCardSummaryRow
                  label={furnitureOptions.sofa.label}
                  value={`${sofasAmount}`}
                  m2="Steam Clean 1m2"
                  coin="USD"
                  price={getSofasPrice(sofasAmount)}
                  amount={sofasAmount}
              />
            }

            {
              sectionalsAmount > 0 && <FurnitureCardSummaryRow
                  label={furnitureOptions.sectional.label}
                  value={`${sectionalsAmount}`}
                  m2="Steam Clean 1m2"
                  coin="USD"
                  price={getSectionalsPrice(sectionalsAmount)}
                  amount={sectionalsAmount}
              />
            }
          </div>

          <div className="p-8 border-t-2 w-full	border-slate-50	border-solid">
            <div className="grid grid-cols-2 w-full">
              <div className="h-16">
                <p className="text-base font-bold">Total</p>
                <p className="text-slate-400 text-sm">
                  <span className="text-rose-500">*</span> Minimum labor price
                  {minimumLaborPrice.toString()}USD
                </p>
              </div>
              <div className="flex justify-end h-16">
                <p className="text-xl text-indigo-500 font-bold">
                  {""}
                  <br className="service-total" /> {showGlobalTotal(globalTotal)} <span className="text-sm text-slate-400">USD</span>
                </p>
              </div>
            </div>
            <div className="flex mt-2 py-4 w-full">
              <button
                className="hover:bg-indigo-400 transition delay-300 duration-300 bg-indigo-500 italic uppercase py-4 px-6 rounded text-white text-sm font-bold w-full text-center"
                onClick={() => setIsModalOpen(true)}
              >
                Book Now!
              </button>
            </div>
          </div>
        </div>
      }

      {/* Area Rug Pricing */}
      {
        selectedService === serviceOptions.areaRug && <div>
          <div className="grid grid-rows-4">
          <NotPricedCardSummaryRow
              label="Rug Area"
              value=""
              m2="Steam Clean Sq.Ft"
            />
            <NotPricedCardSummaryRow
              label="Length"
              value={`${areaRugLength}`}
              m2=""

            />
             <NotPricedCardSummaryRow
              label="Width"
              value={`${areaRugWidth}`}
              m2=""

            />
          </div>

          <div className="p-8 border-t-2 w-full	border-slate-50	border-solid">
            <div className="grid grid-cols-2 w-full">
              <div className="h-16">
                <p className="text-base font-bold">Total</p>
                <p className="text-slate-400 text-sm">
                  <span className="text-rose-500">*</span> Minimum labor price
                  {minimumLaborPrice.toString()}USD
                </p>
              </div>
              <div className="flex justify-end h-16">
                <p className="text-xl text-indigo-500 font-bold">
                  {""}
                  <br className="service-total" /> {showGlobalTotal(globalTotal)} <span className="text-sm text-slate-400">USD</span>
                </p>
              </div>
            </div>
            <div className="flex mt-2 py-4 w-full">
              <button
                className="hover:bg-indigo-400 transition delay-300 duration-300 bg-indigo-500 italic uppercase py-4 px-6 rounded text-white text-sm font-bold w-full text-center"
                onClick={() => setIsModalOpen(true)}
              >
                Book Now!
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  );
};
