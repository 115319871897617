import cn from "classnames";
import { SubTitle } from "../atoms/SubTitle";
import { SummaryCard } from "../molecules/SummaryCard";
import { useSbsContext } from "../../context/sbscontext";
import { FormRowInputs } from "../molecules/FormRowInputs";
import ServiceOptionsMobile from "../molecules/ServiceOptionMobile";
import ServiceOptionsDesktop from "../molecules/ServiceOptionsDesktop";
import chair from '../../imgs/icons/chair.svg'
import loveseat from '../../imgs/icons/loveseat.svg'
import kitchenChair from '../../imgs/icons/kitchenChair.svg'
// import sofa from '../../imgs/icons/sofa.svg'
import sectional from '../../imgs/icons/sectional.svg'
import {commercialCarpetCleaningPrice, areaRugCleaningPrice} from '../molecules/SummaryCard'



export const serviceOptions = {
  carpetCleaning: {
    id: "CARPET_CLEANING",
    label: "Carpet Cleaning",
  },
  commercialCarpetCleaning: {
    id: "COMMERCIAL_CARPET_CLEANING",
    label: "Commercial Carpet Cleaning",
  },
  furnitureCleaning: {
    id: "FURNITURE_CLEANING",
    label: "Furniture Cleaning",
  },
  areaRug: {
    id: "AREA_RUG",
    label: "Area Rug",
  },
};

export const furnitureOptions = {
  chair: {
    id: "CHAIR",
    label: "Chair",
    oneItemPrice: 169.00,
    icon: chair
  },
  loveseat: {
    id: "LOVESEAT",
    label: "Loveseat",
    oneItemPrice: 180.00,
    icon: loveseat
  },
  kitchenchair: {
    id: "KITCHENCHAIR",
    label: "Kitchen Chair",
    oneItemPrice: 120.00,
    icon: kitchenChair
  },
  sofa: {
    id: "sofa",
    label: "Sofa",
    oneItemPrice: 190.00,
    icon: sectional
  },
  sectional: {
    id: "SECTIONAL",
    label: "Sectional",
    oneItemPrice: 210.00,
    icon: sectional
  },
};

export const FormSection = () => {
  const {
    selectedService,
    setSelectedService,
    carpetCleaningRoomsAmount,
    setCarpetCleaningRoomsAmount,
    carpetCleaningEntryOrHallAmount,
    setCarpetCleaningEntryOrHallAmount,
    carpetCleaningStaircaseAmount,
    setCarpetCleaningStaircaseAmount,
    commercialCarpetCleaningArea,
    setCommercialCarpetCleaningArea,
    chairsAmount,
    setChairsAmount,
    loveseatsAmount,
    setLoveseatsAmount,
    kitchenChairsAmount,
    setKitchenChairsAmount,
    sofasAmount,
    setSofasAmount,
    sectionalsAmount,
    setSectionalsAmount,
    areaRugLength,
    setAreaRugLength,
    areaRugWidth,
    setAreaRugWidth,
    selectedFurniture,
    setSelectedFurniture
  } = useSbsContext()

  return (
    <div id="onlinePricing" className="bg-slate-200 w-full py-11 px-10">

      {/* Services Options Menu */}

      <div className="text-base flex justify-end md:justify-start font-bold">
        <div className="hidden md:block">
          <ServiceOptionsDesktop
            serviceOptions={serviceOptions}
            selectedOption={selectedService}
            setSelectedOption={setSelectedService}
          />
        </div>
        <div className="block md:hidden">
          <ServiceOptionsMobile
            serviceOptions={serviceOptions}
            selectedOption={selectedService}
            setSelectedOption={setSelectedService}
          />
        </div>
      </div>

      {/* Services Inputs */}

        <div className="py-2 grid grid-cols-1 md:grid-cols-2 gap-4">

        {/* Carpet Cleaning Inputs */}
        {
          selectedService === serviceOptions.carpetCleaning && <div>
            <SubTitle text="Schedule Appointment" />
            <p className="text-slate-500 text-base">
              We've been carpet cleaning in Charlotte <br /> since since 2021. If
              your carpets need a cleaning give us a try.
            </p>
            <div className="mt-8 w-full">
              <label className="text-slate-800 text-base font-bold mb-2">How many Rooms?</label>
              <div className="d-flex align-items-center mb-4">
                <FormRowInputs text="Steam Clean 1m2"/>
                <input className="p-2 rounded w-20" placeholder={carpetCleaningRoomsAmount} min="0" type="number" onChange={(e) => setCarpetCleaningRoomsAmount(e.target.value)} />
              </div>
              <label className="text-slate-800 text-base font-bold mb-2">How many Entries or Halls?</label>
              <div className="d-flex align-items-center mb-4">
                <FormRowInputs text="Steam Clean 1m2"/>
                <input className="p-2 rounded w-20" placeholder={carpetCleaningEntryOrHallAmount} min="0" type="number" onChange={(e) => setCarpetCleaningEntryOrHallAmount(e.target.value)} />
              </div>
              <label className="text-slate-800 text-base font-bold mb-2">How many Staircases?</label>
              <div className="d-flex align-items-center mb-4">
                <FormRowInputs text="Steam Clean 1m2"/>
                <input className="p-2 rounded w-20" placeholder={carpetCleaningStaircaseAmount} min="0" type="number" onChange={(e) => setCarpetCleaningStaircaseAmount(e.target.value)} />
              </div>
            </div>
          </div>
        }

        {/* Commercial Carpet Cleaning Inputs */}
        {
          selectedService === serviceOptions.commercialCarpetCleaning && <div>
            <SubTitle text="Schedule Appointment" />
            <p className="text-slate-500 text-base">
              Airbnb, hotels, schools, hospitals, offices. Contact
              us for free estimate.
            </p>
            <div className="mt-8 w-full">
              <label className="text-slate-800 text-base font-bold mb-2">Choose the carpet area</label>

              <div className="d-flex align-items-center mb-4">

                <label className="text-slate-800 text-base font-bold mr-2 mb-2">Carpet Area</label>
                <input className="p-2 rounded w-20" placeholder={commercialCarpetCleaningArea} min="0" type="number" onChange={(e) => setCommercialCarpetCleaningArea(e.target.value)} />
              </div>
              <FormRowInputs text={`Steam Clean 1 for $${commercialCarpetCleaningPrice}/ Sq. Ft.`} />
            </div>
          </div>
        }

        {/* Furniture Cleaning Inputs */}
        {
          selectedService === serviceOptions.furnitureCleaning && <div>
            <SubTitle text="Schedule Appointment" />
            <p className="text-slate-500 text-base">
              We've been carpet cleaning in Charlotte <br /> since since 2021. If
              your carpets need a cleaning give us a try.
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-sm-start mt-3">
            {
              Object.values(furnitureOptions).map((furniture) => (
                <div
                  className={cn(
                    { "d-flex flex-column align-items-center mb-4 mx-5": furniture.id !== selectedFurniture.id },
                    { "d-flex flex-column align-items-center mb-4 mx-5 text-indigo-500": furniture.id === selectedFurniture.id }
                  )}
                  key={furniture.id}
                  onClick={() => setSelectedFurniture(furniture)}
                >
                  <img src={furniture.icon} alt={furniture.id} />
                  <span className="mt-2">{furniture.label}</span>
                </div>
              ))
            }
            </div>


            <label className="text-slate-800 text-base font-bold mb-2">Choose how many</label>
            { selectedFurniture === furnitureOptions.chair && <div>
                <button
                  className="hover:bg-indigo-400 transition delay-300 duration-300 bg-indigo-500 italic uppercase mr-2 py-4 px-6 rounded text-white text-sm font-bold w-full md:w-fit text-center mb-2"
                  onClick={() => setChairsAmount(chairsAmount + 1)}
                >
                  Add {selectedFurniture.label}
                </button>
                <button
                  className="hover:bg-indigo-400 transition delay-300 duration-300 bg-indigo-500 italic uppercase py-4 px-6 rounded text-white text-sm font-bold w-full md:w-fit text-center"
                  onClick={() => {
                    if(chairsAmount >= 1) setChairsAmount(chairsAmount - 1)}}
                >
                  Remove {selectedFurniture.label}
                </button>
              </div>
            }
            { selectedFurniture === furnitureOptions.loveseat && <div>
                <button
                  className="hover:bg-indigo-400 transition delay-300 duration-300 bg-indigo-500 italic uppercase mr-2 py-4 px-6 rounded text-white text-sm font-bold w-full md:w-fit text-center mb-2"
                  onClick={() => setLoveseatsAmount(loveseatsAmount + 1)}
                >
                  Add {selectedFurniture.label}
                </button>
                <button
                  className="hover:bg-indigo-400 transition delay-300 duration-300 bg-indigo-500 italic uppercase py-4 px-6 rounded text-white text-sm font-bold w-full md:w-fit text-center"
                  onClick={() => {
                    if(loveseatsAmount >= 1) setLoveseatsAmount(loveseatsAmount - 1)}}
                >
                  Remove {selectedFurniture.label}
                </button>
              </div>
            }
            {
              selectedFurniture === furnitureOptions.kitchenchair && (
                <div>
                  <button
                    className="hover:bg-indigo-400 transition delay-300 duration-300 bg-indigo-500 italic uppercase mr-2 py-4 px-6 rounded text-white text-sm font-bold w-full md:w-fit text-center mb-2"
                    onClick={() => setKitchenChairsAmount(kitchenChairsAmount + 1)}
                  >
                    Add {selectedFurniture.label}
                  </button>
                  <button
                    className="hover:bg-indigo-400 transition delay-300 duration-300 bg-indigo-500 italic uppercase py-4 px-6 rounded text-white text-sm font-bold w-full md:w-fit text-center"
                    onClick={() => {
                      if (kitchenChairsAmount >= 1) {
                        setKitchenChairsAmount(kitchenChairsAmount - 1);
                      }
                    }}
                  >
                    Remove {selectedFurniture.label}
                  </button>
                </div>
              )
            }
            { selectedFurniture === furnitureOptions.sofa && <div>
                <button
                  className="hover:bg-indigo-400 transition delay-300 duration-300 bg-indigo-500 italic uppercase mr-2 py-4 px-6 rounded text-white text-sm font-bold w-full md:w-fit text-center mb-2"
                  onClick={() => setSofasAmount(sofasAmount + 1)}
                >
                  Add {selectedFurniture.label}
                </button>
                <button
                  className="hover:bg-indigo-400 transition delay-300 duration-300 bg-indigo-500 italic uppercase py-4 px-6 rounded text-white text-sm font-bold w-full md:w-fit text-center"
                  onClick={() => {
                    if(sofasAmount >= 1) setSofasAmount(sofasAmount - 1)}}
                >
                  Remove {selectedFurniture.label}
                </button>
              </div>
            }
            { selectedFurniture === furnitureOptions.sectional && <div>
                <button
                  className="hover:bg-indigo-400 transition delay-300 duration-300 bg-indigo-500 italic uppercase mr-2 py-4 px-6 rounded text-white text-sm font-bold w-full md:w-fit text-center mb-2"
                  onClick={() => setSectionalsAmount(sectionalsAmount + 1)}
                >
                  Add {selectedFurniture.label}
                </button>
                <button
                  className="hover:bg-indigo-400 transition delay-300 duration-300 bg-indigo-500 italic uppercase py-4 px-6 rounded text-white text-sm font-bold w-full md:w-fit text-center"
                  onClick={() => {
                    if(sectionalsAmount >= 1) setSectionalsAmount(sectionalsAmount - 1)}}
                >
                  Remove {selectedFurniture.label}
                </button>
              </div>
            }


          </div>
        }

        {/* Area Rug Inputs */}
        {
          selectedService === serviceOptions.areaRug && <div>
            <SubTitle text="Schedule Appointment" />
            <p className="text-slate-500 text-base">
              We've been carpet cleaning in Charlotte <br /> since since 2021. If
              your carpets need a cleaning give us a try.
            </p>
            <div className="mt-8 w-full">
                <label className="text-slate-800 text-base font-bold mb-2">Choose the rug area</label>

                <div className="d-flex align-items-center mb-4">
                    <div className="mr-4">
                      <label className="text-slate-800 text-base font-bold mr-2 mb-2">Length</label>
                      <input className="p-2 rounded w-20" placeholder={areaRugLength} min="0" type="number" onChange={(e) => setAreaRugLength(e.target.value)} />
                    </div>
                    <div className="ml-5">
                      <label className="text-slate-800 text-base font-bold mr-2 mb-2">Width</label>
                      <input className="p-2 rounded w-20" placeholder={areaRugWidth} min="0" type="number" onChange={(e) => setAreaRugWidth(e.target.value)} />
                    </div>
                </div>
                <FormRowInputs text={`Steam Clean 1 for $${areaRugCleaningPrice}/ Sq. Ft.`} />
            </div>
          </div>
        }
          <div className="flex justify-center">
            <SummaryCard />
          </div>
        </div>


    </div>
  );
};
