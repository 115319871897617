import emailjs from 'emailjs-com'
import { CustomButton } from "../atoms/CustomButton";
import { FormSection } from "../organisms/FormSection";
// import { OtherServices } from "../organisms/OtherServices";
import { RugComparisonSection } from "../organisms/RugComparisonSection";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import "./style.css";
import { SubTitle } from "../atoms/SubTitle";
import { useSbsContext } from "../../context/sbscontext"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { minimumLaborPrice } from '../molecules/SummaryCard'


export const Home = () => {
  const {
    isModalOpen,
    setIsModalOpen,
    carpetCleaningTotal,
    commercialCarpetCleaningTotal,
    furnitureCleaningTotal,
    areaRugCleaningTotal,
    carpetCleaningRoomsAmount,
    carpetCleaningEntryOrHallAmount,
    carpetCleaningStaircaseAmount,
    commercialCarpetCleaningArea,
    chairsAmount,
    loveseatsAmount,
    kitchenChairsAmount,
    sofasAmount,
    sectionalsAmount,
    areaRugLength,
    areaRugWidth
  } = useSbsContext()

  // Form inputs
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');

  let carpetCleaningMessage = `Carpet Cleaning: Rooms: ${carpetCleaningRoomsAmount}, Entry or Halls: ${carpetCleaningEntryOrHallAmount}, Staircases: ${carpetCleaningStaircaseAmount}, TOTAL: ${carpetCleaningTotal} `
  let commercialCarpetCleaningMessage = `Commercial Carpet Cleaning: Carpet Area: ${commercialCarpetCleaningArea}, TOTAL: ${commercialCarpetCleaningTotal}`
  let furnitureCleaningMessage = `Furniture Cleaning: Chairs: ${chairsAmount}, Loveseats: ${loveseatsAmount}, Kitchen Chairs: ${kitchenChairsAmount}, Sofas: ${sofasAmount}, Sectionals: ${sectionalsAmount}, TOTAL: ${furnitureCleaningTotal} `
  let areaRugCleaningMessage = `Area Rug Cleaning: Length: ${areaRugLength}, Width: ${areaRugWidth}, TOTAL: ${areaRugCleaningTotal} `

  let templateParams = {
    name: name,
    phone: phone,
    email: email,
    address: address,
    total: 0,
    message: ''
  }

  //Send email and close modal
  const submitForm = (e) => {
    e.preventDefault()

    let total = 0
    let message = ''

    // Carpet cleaning email total
    if(carpetCleaningRoomsAmount > 0 || carpetCleaningEntryOrHallAmount > 0 || carpetCleaningStaircaseAmount > 0){
      total += parseInt(carpetCleaningTotal)
      message += carpetCleaningMessage
    }

    // Commercial Carpet cleaning email total
    if(commercialCarpetCleaningArea > 0) {
      total += parseInt(commercialCarpetCleaningTotal)
      message = message.concat( '  |  ', commercialCarpetCleaningMessage)
    }

    // Furniture cleaning email total
    if(chairsAmount > 0 || loveseatsAmount > 0 || kitchenChairsAmount > 0 || sofasAmount > 0 || sectionalsAmount > 0){
      total += parseInt(furnitureCleaningTotal)
      message = message.concat( '  |  ', furnitureCleaningMessage)
    }

    // Area Rug cleaning email total
    if(areaRugLength > 0 || areaRugWidth > 0){
      total += parseInt(areaRugCleaningTotal)
      message = message.concat( '  |  ', areaRugCleaningMessage)
    }

    templateParams['total'] = total <= minimumLaborPrice ? minimumLaborPrice : total
    templateParams['message'] = message


    emailjs.send('service_1uwsx5n', 'template_087d2v7', templateParams, 'lHsV-y4LDmWbl98qQ')
      .then(function(response) {
          toast.success("Email successfully sent!", {
            position: toast.POSITION.TOP_RIGHT
          })
      }, function(error) {
          toast.error("There was error while sending your email. Please try again later", {
            position: toast.POSITION.TOP_RIGHT
          })
      });

    setIsModalOpen(false)
  }

  return (
    <div className="flex flex-col w-full">
      <div className="homeBg w-full bg-no-repeat bg-cover flex-col flex md:flex-row py-16 md:py-44 px-11">
        <div className="w-full md:w-3/4 py-8">
          <h1 className="text-slate-900 break-words text-5xl md:text-7xl italic font-black my-4">
            Experienced <span className="text-indigo-500">Cleaning</span>{" "}
            Services.
          </h1>
          <p className="text-slate-900 text-lg">
            We make the cleaning effortless.
          </p>
          <div className="rectangle bg-indigo-500 my-5" />
          <br />
          <CustomButton
            className="my-5"
            text="Get Free Estimate"
            type="button"
            path="#onlinePricing"
          />
        </div>
        <div className="hidden md:w-1/4" />
      </div>
      <RugComparisonSection />
      <FormSection />
      {/* <OtherServices /> */}

      {/*Modal*/}
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsModalOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto mt-4">
            <div className="flex items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="mt-5 w-full max-w-[750px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title>
                    <SubTitle text="Schedule Appointment" />
                  </Dialog.Title>
                  <div className="my-2">
                    <p className="text-base text-slate-500">
                      Perhaps there’s a way your business could benefit from
                      simplifying a process or experience? Please share your
                      thoughts in the form below and we’ll be in touch. We’d
                      love to hear from you.
                    </p>
                  </div>
                  <form className="grid grid-cols-1 md:grid-cols-2	gap-4 py-2.5">
                    <div>
                      <label className="text-slate-800 text-base font-bold my-2">
                        Name
                      </label>
                      <input
                        className="relative w-full cursor-default rounded-lg bg-slate-50 py-4 mr-2 px-6 text-left border border-slate-3000 text-slate-800 text-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-100 sm:text-sm"
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Full Name"
                        onChange={(e) => setName(e.target.value)}
                      ></input>
                    </div>
                    <div>
                      <label className="text-slate-800 text-base font-bold my-2">
                        Email
                      </label>
                      <input
                        className="relative w-full cursor-default rounded-lg bg-slate-50 py-4 mr-2 px-6 text-left border border-slate-3000 text-slate-800 text-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-100 sm:text-sm"
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                      ></input>
                    </div>
                    <div>
                      <label className="text-slate-800 text-base font-bold my-2">
                        Phone Number
                      </label>
                      <input
                        className="relative w-full cursor-default rounded-lg bg-slate-50 py-4 mr-2 px-6 text-left border border-slate-3000 text-slate-800 text-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-100 sm:text-sm"
                        type="number"
                        id="phone"
                        name="phone"
                        placeholder="Phone Number"
                        onChange={(e) => setPhone(e.target.value)}
                      ></input>
                    </div>
                    <div>
                      <label className="text-slate-800 text-base font-bold my-2">
                        Address
                      </label>
                      <input
                        className="relative w-full cursor-default rounded-lg bg-slate-50 py-4 mr-2 px-6 text-left border border-slate-3000 text-slate-800 text-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-100 sm:text-sm"
                        type="text"
                        id="address"
                        name="address"
                        placeholder="Your Address"
                        onChange={(e) => setAddress(e.target.value)}
                      ></input>
                    </div>
                  </form>
                  <div className="my-3 w-full">
                    <button
                      onClick={submitForm}
                      className="hover:bg-indigo-400 items-center transition delay-300 duration-300 bg-indigo-500 italic uppercase py-4 px-6 rounded text-white text-sm font-bold w-full text-center"
                    >
                      Book Now!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <ToastContainer />
    </div>
  );
};
