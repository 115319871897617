export const FurnitureCardSummaryRow = ({ label, value, price, coin }) => {
    return (
      <div className="grid w-full grid-cols-2 py-2 px-8">

        <div className="h-16">
          <div className="d-flex mt-4">
            <p className="text-base font-bold mr-2 mb-2">{label}</p>
            <p className="text-base font-bold">(x{value})</p>
          </div>
        </div>

        <div className="flex justify-end h-16">
          <p className="text-base font-bold">
            {""}
            <br /> {price} <span className="text-slate-400">{coin}</span>
          </p>
        </div>
      </div>
    );
  };