import { createContext } from "react";
import { useContext, useState } from "react";
import { furnitureOptions, serviceOptions } from "../components/organisms/FormSection";

const SbsContext = createContext({})

export const SbsContextProvider = (props) => {

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedService, setSelectedService] = useState(serviceOptions.carpetCleaning)

    // Global Total
    const [globalTotal, setGlobalTotal] = useState(0)

    // Carpet Cleaning
    const [carpetCleaningRoomsAmount, setCarpetCleaningRoomsAmount] = useState(0)
    const [carpetCleaningEntryOrHallAmount, setCarpetCleaningEntryOrHallAmount] = useState(0)
    const [carpetCleaningStaircaseAmount, setCarpetCleaningStaircaseAmount] = useState(0)
    const [carpetCleaningTotal, setCarpetCleaningTotal] = useState(0)

    // Commercial Carpet Cleaning
    const [commercialCarpetCleaningArea, setCommercialCarpetCleaningArea] = useState(0)
    const [commercialCarpetCleaningTotal, setCommercialCarpetCleaningTotal] = useState(0)

    // Furniture Cleaning
    const [selectedFurniture, setSelectedFurniture] = useState(furnitureOptions.chair)
    const [chairsAmount, setChairsAmount] = useState(0)
    const [loveseatsAmount, setLoveseatsAmount] = useState(0)
    const [kitchenChairsAmount, setKitchenChairsAmount] = useState(0)
    const [sofasAmount, setSofasAmount] = useState(0)
    const [sectionalsAmount, setSectionalsAmount] = useState(0)
    const [furnitureCleaningTotal, setFurnitureCleaningTotal] = useState(0)

    // Area Rug
    const [areaRugLength, setAreaRugLength] = useState(0)
    const [areaRugWidth, setAreaRugWidth] = useState(0)
    const [areaRugCleaningTotal, setAreaRugCleaningTotal] = useState(0)


    return (
        <SbsContext.Provider value={{
            isModalOpen,
            setIsModalOpen,
            selectedService,
            setSelectedService,
            carpetCleaningRoomsAmount,
            setCarpetCleaningRoomsAmount,
            carpetCleaningEntryOrHallAmount,
            setCarpetCleaningEntryOrHallAmount,
            carpetCleaningStaircaseAmount,
            setCarpetCleaningStaircaseAmount,
            commercialCarpetCleaningArea,
            setCommercialCarpetCleaningArea,
            chairsAmount,
            setChairsAmount,
            loveseatsAmount,
            setLoveseatsAmount,
            kitchenChairsAmount,
            setKitchenChairsAmount,
            sofasAmount,
            setSofasAmount,
            sectionalsAmount,
            setSectionalsAmount,
            areaRugLength,
            setAreaRugLength,
            areaRugWidth,
            setAreaRugWidth,
            selectedFurniture,
            setSelectedFurniture,
            carpetCleaningTotal,
            setCarpetCleaningTotal,
            commercialCarpetCleaningTotal,
            setCommercialCarpetCleaningTotal,
            furnitureCleaningTotal,
            setFurnitureCleaningTotal,
            areaRugCleaningTotal,
            setAreaRugCleaningTotal,
            globalTotal,
            setGlobalTotal
        }}>
            {props.children}
        </SbsContext.Provider>)
}

export const useSbsContext = () => {
    const context = useContext(SbsContext)
    return context
}