
export const CustomButton = (props) => {
  return (
    <>
      <a
        className="d-flex justify-content-center align-items-center hover:bg-indigo-400 transition delay-300 duration-300 bg-indigo-500 italic uppercase py-4 px-6 rounded text-white text-sm font-bold w-full md:w-fit text-center"
        href={props.path}
        onClick={props.onClick}
      >{props.text}</a>
    </>
  );
};