import SBSLogo from "../../imgs/sbs-logo.png";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { CustomButton } from "../atoms/CustomButton";
import { LabelFooter } from "../atoms/LabelFooter";
import { HoursSelectFooter } from "../atoms/HoursSelectFooter";
import { ServiceSelectFooter } from "../atoms/ServiceSelectFooter";
import { useSbsContext } from "../../context/sbscontext";
import "./style.css";

export const Footer = () => {

  const { setIsModalOpen } = useSbsContext()

  return (
    <div className="bg-slate-900 text-white w-full py-4 px-10">
      <div className="grid grid-cols-1 lg:grid-cols-2 py-8 gap-20">
        <div className="p-4">
          <div className="ml-[-1em] pb-4">
            <img src={SBSLogo} alt="logo" />
          </div>
          <p className="text-slate-400 mb-8">
            Treat every customer in such a memorable way that when our
            interaction is complete, the customer tells someone else how GREAT
            it was!
          </p>
          <div className="w-full">
            <LabelFooter text="Location" />
            <div className="flex flex-col">
                <p className="text-slate-400 mb-8">
                  Charlotte, North Carolina
                </p>
                <p className="text-slate-400 mb-8">
                  Orlando, Florida
                </p>
              <div className="flex gap-4">
                <CustomButton
                  text={<PhoneIcon className=" phoneIcon" />}
                  path="tel:+1(980) 409-3359"
                />
                <CustomButton
                  text={<EnvelopeIcon className=" phoneIcon" />}
                  path="mailto:info@sbscleaningnc.com"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="flex w-full items-center my-2">
            <div className="flex w-full flex-col">
              <LabelFooter text="Hours" />
              <div className="flex-col md:flex-row w-full flex z-10 items-center gap-4">
                <HoursSelectFooter />
                <CustomButton
                  text="Contact Us"
                  onClick={() => setIsModalOpen(true)}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full items-center my-2">
            <div className="flex w-full flex-col">
              <LabelFooter text="Other Services" />
              <div className="flex-col flex md:flex-row items-center gap-4">
                <ServiceSelectFooter />
                <CustomButton
                  text="Book Now"
                  onClick={() => setIsModalOpen(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-8 mt-4 text-center border-solid border-slate-800 border-t-2">
        <p className="text-slate-400 mb-8 text-xs">© 2023 All Rights Reserved | SBS Cleaning. Termns & conditions.</p>
      </div>
    </div>
  );
};
