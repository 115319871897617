export const NotPricedCardSummaryRow = ({ label, value, m2 }) => {
    return (
      <div className="grid w-full grid-cols-1 py-2 px-8">

        <div className="h-16">
          <p className="text-base font-bold mb-2">{label}</p>
          <div className="d-flex">
            <p className="text-base font-bold mr-2">{value}</p>
            <p className="text-slate-400">{m2}</p>
          </div>
        </div>

      </div>
    );
  };