// import {Link} from 'react-router-dom';
import { PhoneIcon } from '@heroicons/react/24/solid';
import SBSLogo from '../../imgs/sbs-logo.png';
import './style.css';

export const Navbar =() => {
  return (
    <div className="bg-indigo-500 z-50 fixed w-full py-4 px-10 flex items-center justify-between">
      <div>
        <img src={SBSLogo} alt="logo" />
      </div>

      <div className="sm:flex hidden">
        {/* <Link className="text-lg font-semibold text-white mx-4 hover:text-indigo-600 transition delay-300 duration-300 ease-in-out" to="/">Home</Link> */}

        {/* <a
          className="text-lg font-semibold text-white mx-4 hover:text-indigo-600 transition delay-300 duration-300 ease-in-out"
          href="#other_services_section"
        >
          Services
        </a> */}

        <a href="tel:+1(980) 409-3359" className="flex items-center text-lg font-semibold text-white mx-4 hover:text-indigo-600 transition delay-300 duration-300 ease-in-out">
          <PhoneIcon className="mr-2 phoneIcon" />
          (980) 409-3359
        </a>
      </div>

    </div>
  );
};
