
export const FormRowInputs = ({ text }) => {
  return (
    <div className="flex flex-col my-2">
      <div className="w-full sm:w-80 flex items-center">
        <div
          className="relative w-full cursor-default rounded-lg bg-slate-50 py-2 mr-2 px-6 text-left border border-slate-3000 text-slate-800 text-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-100 sm:text-sm"
          id="fname"
          name="fname"
        >
          {text}
        </div>
      </div>
    </div>
  );
};
